@mixin long-shadow(
  $type,
  $color,
  $length,
  $fadeout: true,
  $skew: false,
  $direction: right
) {
  $shadow: "";
  @if $skew == false or $type == text {
    @if $direction == right {
      @for $i from 0 to $length - 1 {
        $shadow: $shadow + $i + "px " + $i + "px 0 " + $color + ",";
      }
    }
    @if $direction == left {
      @for $i from 0 to $length - 1 {
        $shadow: $shadow + $i * -1 + "px " + $i + "px 0 " + $color + ",";
      }
    }
  }
  @if $fadeout == true {
    @for $i from 1 to $length - 1 {
      @if $type == text or $skew == false {
        @if $direction == right {
          $shadow: $shadow +
            $i +
            "px " +
            $i +
            "px 0 " +
            rgba($color, 1 - $i / $length) +
            ",";
        }
        @if $direction == left {
          $shadow: $shadow +
            $i *
            -1 +
            "px " +
            $i +
            "px 0 " +
            rgba($color, 1 - $i / $length) +
            ",";
        }
      }
      @if ($type == box) and $skew == true {
        @if $direction == right {
          $shadow: $shadow +
            $i +
            "px " +
            $i +
            "px 0 " +
            $i *
            0.2 +
            "px " +
            rgba($color, 1 - $i / $length) +
            ",";
        }
        @if $direction == left {
          $shadow: $shadow +
            $i *
            -1 +
            "px " +
            $i +
            "px 0 " +
            $i *
            0.2 +
            "px " +
            rgba($color, 1 - $i / $length) +
            ",";
        }
      }
    }
    $shadow: $shadow + $length + "px " + $length + "px 0 " + rgba($color, 0);
  }
  @if $fadeout == false {
    @if $skew == true and ($type == box) {
      @for $i from 0 to $length - 1 {
        $shadow: $shadow +
          $i +
          "px " +
          $i +
          "px 0 " +
          $i *
          0.1 +
          "px " +
          $color +
          ",";
      }
    }
    $shadow: $shadow + $length + "px " + $length + "px 0 " + rgba(0, 0, 0, 0);
  }
  $shadow: unquote($shadow);
  @if $type == "box" {
    box-shadow: $shadow;
  }
  @if $type == "text" {
    text-shadow: $shadow;
  }
}
