@import "mixins";
@import "custom";
@import "prism";
@import "syntax_highlight";

@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/utilities/api";

.long-shadow {
  @include long-shadow(text, $red-700, 400, false, false, left);
}

img {
  @extend .img-fluid;
}

table {
  @extend .table;
}

blockquote {
  @extend .blockquote;
  @extend .p-2;
  @extend .bg-blue-100;
  @extend .text-blue-800;
  @extend .fs-6;
  border-left-width: 5px;
  border-left-color: $blue-800;
  border-left-style: solid;
}

.highlighter-rouge pre {
  @extend .p-3;
}

.highlighter-rouge code {
  word-break: keep-all;
  word-wrap: normal;
}

code {
  padding: $kbd-padding-y $kbd-padding-x;
  @include font-size($kbd-font-size);
  color: $kbd-color;
  background-color: $kbd-bg;
  @include border-radius($border-radius-sm);
}
