@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";

$font-family-sans-serif: "Inter", sans-serif;
$body-color: $gray-600;
$headings-font-weight: 900;
$headings-color: $gray-700;
$card-title-color: $headings-color;
$body-bg: $gray-200;
$kbd-bg: $gray-700;

$card-border-radius: 0;
$card-border-width: 0;

$link-color: $blue-700;

$btn-border-radius: 0;

$input-border-radius: 0px;
$input-bg: #ffffff;

$theme-colors: map-merge($theme-colors, $reds);
$theme-colors: map-merge($theme-colors, $blues);
$theme-colors: map-merge($theme-colors, $grays);
